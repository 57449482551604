import _ from 'lodash'
import axios from 'axios'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import Echo from 'laravel-echo'

import Pusher from 'pusher-js'

//lodash
window._ = _
//axios
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// dayjs
window.dayjs = dayjs
window.dayjs.extend(advancedFormat)
window.dayjs.extend(timezone)
window.dayjs.extend(utc)
window.dayjs.extend(relativeTime)
window.dayjs.extend(localizedFormat)
window.dayjs.extend(quarterOfYear)

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */
if (import.meta.env.VITE_ABLY_APP_ID) {
    window.Pusher = Pusher
    window.Echo = new Echo({
        broadcaster: 'pusher',
        app_id: import.meta.env.VITE_ABLY_APP_ID,
        key: import.meta.env.VITE_ABLY_KEY,
        secret: import.meta.env.VITE_ABLY_SECRET,
        cluster: 'us1',
        wsHost: 'realtime-pusher.ably.io',
        wsPort: 443,
        disableStats: true,
        encrypted: true,
    })
}
